@font-face {
    font-family: 'Roobert-Regular';
    src: url('/assets/fonts/Roobert/Roobert-Regular.otf');
}
@font-face {
    font-family: 'Roobert-Bold';
    src: url('/assets/fonts/Roobert/Roobert-Bold.otf') ;
}
@font-face {
    font-family: 'Roobert-Light';
    src: url('/assets/fonts/Roobert/Roobert-Light.otf') ;
}
@font-face {
    font-family: 'Roobert-Medium';
    src: url('/assets/fonts/Roobert/Roobert-Medium.otf') ;
}
@font-face {
    font-family: 'Roobert-SemiBold';
    src: url('/assets/fonts/Roobert/Roobert-SemiBold.otf') ;
}