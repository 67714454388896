// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import 'css-star-rating/scss/star-rating';

*:focus {
  outline: 0;
}

.basic-container {
  height: 100%;
}

// Zoom
#zmmtg-root {
  display: none;
  transform-origin: top left;
}

// .sdk-select {
//     height: 34px;
//     border-radius: 4px;
// }

// .websdktest button {
//     float: right;
//     margin-left: 5px;
// }

// #nav-tool {
//     margin-bottom: 0px;
// }

// #show-test-tool {
//     position: absolute;
//     top: 100px;
//     left: 0;
//     display: block;
//     z-index: 99999;
// }

// #display_name {
//     width: 250px;
// }

// #websdk-iframe {
//     width: 700px;
//     height: 500px;
//     border: 1px;
//     border-color: red;
//     border-style: dashed;
//     position: fixed;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     left: 50%;
//     margin: 0;
// }

/* Login */
ion-button {
  text-transform: capitalize !important;
}

.pwd-btn {
  --background: transparent;
  --box-shadow: none;
  margin: auto;
  --background-hover: #80808024;
  --background-focused: #80808024;
}

.color-1st-screen {
  color: var(--text-color-1st-login);

  a {
    color: var(--text-color-1st-login) !important;
  }
}

.color-2nd-screen {
  color: var(--text-color-2nd-login-mobile);

  a {
    color: var(--text-color-2nd-login-mobile) !important;
  }
}

.access-btn {
  --background: var(--valid-btn-bg-color);
  color: var(--valid-btn-color);
  --background-hover: var(--btn-bg-color-hover);
  --background-focused: var(--btn-bg-color-hover);
}

.access-w-code-btn {
  --background: var(--login-btn-bg-color);
  color: var(--login-btn-color);
  --border-color: var(--login-btn-color);
}

.space-condition {
  padding-left: 10px;
  font-family: 'Roobert-Regular';
  font-size: 1rem;

  > a {
    text-decoration: underline;
    cursor: pointer;
  }
}

.login-title {
  font-family: 'Roobert-Bold';
  font-size: 1.6em;
  text-align: center;
}

.explanation-txt {
  font-family: 'Roobert-Regular';
}

/* end Login */

.authBtns {
  ion-button {
    width: 50%;
    height: 3rem;
    font-family: var(--font);
    font-weight: 600;
    font-size: 0.8rem;
    border-radius: 50px;
  }
}

ion-back-button {
  --icon-font-size: 20px !important;
  width: 2.5rem;
  height: 2.5rem;
}

.menu-footer {
  display: flex;
  height: 100px;

  > .btns-log-back {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > ion-button {
      height: 40px !important;
    }

    span {
      font-family: var(--font);
      font-weight: 600;
      text-transform: none;
      min-width: 120px;
      margin-left: 8px;
    }
  }

  .btn-menu-lang {
    border: 1px solid;
    border-radius: 16px;
    width: 105%;

    > .lang-name {
      font-size: 12px;
      margin: 0 6px 0 3px;
    }

    > ion-icon {
      width: 12px;
      height: 12px;
    }
  }
}

.menu-user-avatar {
  width: 44px;
  height: 44px;
  border: 2px solid grey;
  cursor: pointer;
  // position: fixed;
  // right: 10px;
  

  img {
    width: 42px;
  }

  > .profile-picture {
    object-fit: cover;
    object-position: center;
    margin: 0 auto;
    background: var(--bg-color);
    padding: 11px;
    border-radius: 50%;

    > .cus-picture {
      // width: 14px;
      // height: 14px;
      margin: auto;
      border-radius: 0;
      opacity: 0.6;
    }
  }

  #profile-badge {
    position: absolute;
    top: 0px;
    right: -5px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    font-size: 9px;
    font-family: var(--font);
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.btn-notifs-menu {
  position: relative;

  ion-icon {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
  }

  div {
    width: 0.65rem;
    height: 0.65rem;
    border-radius: 50%;
    position: absolute;
    color: transparent;
    z-index: 999999;
    background: var(--active-menu-item);
  }

  #notify-badge-menu {
    right: 25%;
    top: 5%;
  }
}

.toolbar-bottom-border {
  border-bottom: 1px solid rgb(32 17 87 / 0.1);
}

.menu-item-active {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 5px;
  height: 100%;
  margin-right: 11px;
  font-family: var(--font);
  font-weight: 700;
}

.toolbar-title-in-big-screen {
  display: none;
  font-family: var(--font);
  font-weight: 700;
  font-size: 20px;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100vw;
    text-align: left;
  }
}

.toolbar-title-in-small-screen {
  display: block;
  font-family: var(--font);
  font-weight: 700;
  font-size: 30px;

  p {
    margin: 18px 0;
    text-align: left;
  }
}

.hamburger-activated {
  --width: 80px !important;
  width: 80px !important;
  --min-width: 80px !important;
  min-width: 80px !important;
  --max-width: 80px !important;
  max-width: 80px !important;
  transition: all 0.3s ease-in-out 0s;

  .hamburger-header {
    display: flex;
    flex-direction: column;
  }

  .hamburger-items {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: var(--font);
    font-weight: 600;
  }

  .menu-icon {
    font-size: 1.4em;
  }
}

.menu-list-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  ion-label {
    font-family: var(--font);
  }
}

.splash-container {
  z-index: 999999;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;

  .loader-container {
    position: absolute;
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  .splash-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.logout-icon {
  cursor: pointer;
  font-size: 20px;
}

.exit-icon {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: 'FlipH';
  font-size: 20px;
}

// .toolbar-custom-title {
//     width: 100vw !important;
//     text-align: center !important;
// }

.supreme-title {
  display: flex !important;
  justify-content: center !important;
  color: #fff;
}

ion-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 1em;

  * {
    text-align: center;
    font-family: var(--font);
    font-weight: 700;
  }
}

ion-title p {
  // text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100vw;
  // text-align: center;
}

.modal-order-by {
  --background: var(--bg-color-modal);
}

// BG
.background {
  width: 100%;
  max-height: calc(100vh - 56px);
  background: #fff;
}

.align-btn {
  text-align: center;
}

.btn-legal {
  text-transform: uppercase;
  width: 60%;
}

.btn-default {
  margin: 0 auto;
  display: block;
  width: 500px; // <---  add Emmanuel Aires (before: 100%)
  max-width: 60%; // <---  add Emmanuel Aires
}

.divider-default {
  margin-top: 12px;
}

.icon-exit {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: 'FlipH';
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.menu-align {
  height: 100%;
  display: flex;
  justify-content: center;
}

.menu-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 3px;
  display: block;
  object-fit: cover;
  object-position: center;
}

.menu-name {
  font-size: 0.9em;
  text-align: center;
}

.menu-icon {
  font-size: 1.1em;
}

.tabBarIcon {
  font-size: 2em;
}

.menu-icon-img {
  width: 1.1rem;
  height: 1.06rem;
}

.menu-btn-style {
  cursor: pointer;

  ion-item {
    height: 57px;
  }
}

// .scroll-content {
//     overflow-y: hidden !important;
// }
// **** PADDINGS **** //
.padding-top-10 {
  padding-top: 10px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-top-60 {
  padding-top: 60px;
}

.padding-top-80 {
  padding-top: 80px;
}

.padding-top-100 {
  padding-top: 100px;
}

.padding-top-150 {
  padding-top: 150px;
}

.padding-top-200 {
  padding-top: 200px;
}

.padding-left-10 {
  padding-left: 10px;
}

.custom-col {
  width: 100%;
  margin: 0;
  padding: 0;
}

.custom-card {
  border-radius: 10px !important;
}

.full-card {
  //margin: 0 auto;
  margin-top: 100px;
  padding: 0;
  border-radius: 0;
  width: 100%; //
}

.toolbar-background {
  background-color: var(--toolbar-background);
  border-bottom: none;
}

.hide-card {
  box-shadow: none !important;
  padding-bottom: 15px;
  margin: 0;
  width: 100%;
}

.btn-menu {
  text-transform: uppercase;
  font-size: 0.9em;
}

.img-avatar {
  height: 100px;
  width: 100px;
  border: 1px solid #fff;
  border-radius: 50%;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.menuIcons {
  color: rgba(0, 0, 0, 0.603);
}

input.cordova-camera-select {
  position: absolute !important;
  z-index: 999999 !important;
  top: 28% !important;
  left: 45% !important;
}

.btn-cancel-scanqr {
  position: absolute !important;
  z-index: 9999999999999 !important;
}

.icon-size {
  font-size: 30px !important;
}

.icon-size-back {
  font-size: 25px !important;
}

ion-popover {
  top: 30px !important;
}

ion-backdrop {
  //top: -30px !important;
  height: 100vh;
}

.white-color {
  background: #ffffff;
}

.text-center {
  text-align: center !important;
}

.ion-color-blue-default {
  --ion-color-base: var(--ion-color-blue-default) !important;
  --ion-color-base-rgb: var(--ion-color-blue-default-rgb) !important;
  --ion-color-contrast: var(--ion-color-blue-default-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-blue-default-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-blue-default-shade) !important;
  --ion-color-tint: var(--ion-color-blue-default-tint) !important;
}

// loader
.loader-default {
  width: 45px;
  height: 45px;
  display: inline-flex;
  padding: 0px;
  border-radius: 100%;
  border: 5px solid;
  border-top-color: #2f73a2;
  border-bottom-color: rgba(255, 255, 255, 0.3);
  border-left-color: #639ebc;
  border-right-color: rgba(255, 255, 255, 0.3);
  -webkit-animation: loader-default 1s ease-in-out infinite;
  animation: loader-default 1s ease-in-out infinite;
}

.align-loader {
  margin-top: 15px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

@keyframes loader-default {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-default {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.item ion-thumbnail ion-img,
.item ion-thumbnail img {
  // width: auto !important;
  // height: 30px !important;
}

.item ion-thumbnail,
.item ion-thumbnail {
  margin: 0 auto !important;
  display: flex !important;
  align-items: center !important;
}

#notifyBtn,
#chatBtn {
  position: relative;
  font-size: 1.3em;
}

#menu-btn-toggle {
  position: relative;
}

#notify-badge,
#chat-badge,
#menu-badge-ctrl {
  position: absolute;
  top: 1px;
  right: 7px;
  border-radius: 50%;
  // --background: #EB266A;
  width: 12px;
  height: 12px;
  color: transparent;
}

.menu-badges-align {
  width: 100%;
  height: 100%;
  margin-top: 4px;
}

#badges-menu {
  display: flex;
  justify-content: center;
  // margin: 0 auto;
  // display: block;
}

.calendar-button {
  text-decoration: none !important;
  color: #8b8b8b !important;
}

.legal-btn {
  margin-top: 3%;
  margin-bottom: 3%;
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: var(--font);
  font-weight: 700;
  cursor: pointer;
}

.btn-legal-custom {
  background: transparent !important;
  font-size: 1em;
  letter-spacing: 0.09rem;
  text-align: center;
  text-decoration: underline;
}

.language-no-user {
  padding: 6px 6px 0 6px;
  margin-bottom: -15px;
}

.align-all-btns {
  padding: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn-user-null {
  display: flex;
  align-items: center;
  margin-right: 5px;
  font-size: 1em;
  border: 1px solid;
  padding: 10px;
  border-radius: 15px;
  background: transparent !important;
}

.menu-event-banner {
  width: 100%;
  max-height: 120px;
  object-fit: contain;
  object-position: top;
}

ion-modal {
  .modal-wrapper {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    display: block !important;
    width: 100vw !important;
    height: 100vh !important;
  }
}

ion-footer {
  z-index: 1;
}

.ion-footer-network {
  position: fixed;
  bottom: 0;
  width: 100vw;
  text-align: center;
  background: rgba(0, 0, 0, 0.774);
  color: #ffffff;
  padding: 10px;
}

span.star {
  font-size: 1.9em !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.action-sheet-group {
  overflow: auto !important;
}

.red-text {
  color: #c91f1f;
}

.clickable-component {
  cursor: pointer;
}

.clickable-component:hover {
  opacity: 0.8;
}

// modif manu <---  add Emmanuel Aires for align in webview in computer
ion-card {
  display: block;
  max-width: 700px;
  margin: 10px auto !important;
  width: 95%;
}

.split-pane-md.split-pane-visible > .split-pane-side {
  // max-width: 22%;
  max-width: 295px;
}

// modif manu <---  add Emmanuel Aires for align in webview in computer

// Track css

.track-name {
  border-radius: 8px;
  padding: 1px 3px;
  font-weight: 300;
  font-size: 0.7em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: max-content;
  float: left;
  margin-right: 3px;
  margin-bottom: 4px;
}

.searchbar-input {
  border: 0 !important;
  box-shadow: none !important;
}

ion-searchbar {
  .searchbar-input {
    border-radius: 28px !important;
  }

  input {
    box-shadow: none;
  }
}

@media screen and (min-width: 768px) {
  .toolbar-title-in-small-screen {
    display: none;
  }

  .toolbar-title-in-big-screen {
    display: block;
  }

  .background {
    width: 70%;
    // max-height: calc(100vh - 56px) !important;
    margin: 0 auto;
  }

  .color-2nd-screen {
    color: var(--text-color-2nd-login-desktop);

    a {
      color: var(--text-color-2nd-login-desktop) !important;
    }
  }

  ion-title {
    text-align: left;
    position: relative;

    * {
      text-align: left;
    }
  }

  .my-custom-class .action-sheet-group {
    .action-sheet-button {
      height: 60px;
    }
  }
}

/* unsupported browser */
.unsupported_browser {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: 45px;
    font-weight: 600;
  }

  a {
    cursor: pointer;
  }

  .b3app_logo {
    margin-top: 2%;
    width: 200px;
    height: 200px;
  }

  p {
    text-align: center;
    width: 40%;
  }

  .chome_logo {
    width: 50px;
    height: 50px;
  }
}

.my-custom-class .action-sheet-group {
  .action-sheet-button {
    height: 90px;
    font-size: 0.9rem;
  }
}

/* Custom css scrollbar */
// .custom_scrollbar {
// 	--offset-bottom: auto !important;
// 	--overflow: hidden;
// 	overflow: auto;
// 	&::-webkit-scrollbar {
// 		width: 8px;
// 		-ms-overflow-style: none; // IE 10+
// 		// scrollbar-width: none; // Firefox
// 		scroll-behavior: smooth;
// 	}
// 	&::-webkit-scrollbar-thumb {
// 		// background-color: #00000065 !important;
// 		background-color: #00000065 !important;
// 		border-radius: 12px !important;
// 	}
// }

// .hide_scrollbar {
// 	-ms-overflow-style: none !important; /* IE and Edge */
// 	scrollbar-width: none !important; /* Firefox */
// 	&::-webkit-scrollbar {
// 		display: none !important; /*Chrome, Safari & Opera */
// 	}
// }

.nome-evento{
  position: fixed;
  left: 0px;
  font-size: 19px;
  width: 200px!important;

}

ion-tab-bar {
  --color-selected: var(--tab-bar-color);
}

.scanner-ui { display: none; }
.scanner-hide { visibility: visible; }

body.scanner-active {
    --background: transparent;
    --ion-background-color: transparent;
}

body.scanner-active .scanner-ui { 
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;

    .scan-box {
        position: absolute;
        border: 2px solid #ffffff;
        box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.5);
        //content: '';
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 300px;
        width: 300px;

        .scan-line {
            position: relative;
            width: 100%;
            border: 1px solid red;
            height: 1px;
            animation-name: scan-line;
            animation-duration: 4s;
            animation-timing-function: linear;
	        animation-iteration-count: infinite;
        }

        @keyframes scan-line {
            0% { top: 0px; }
            50% { top: 295px; }
            100% { top: 0px; }
        }
    }

    .scan-close-button {
        position: absolute;
        width: 300px;
        bottom: 30px;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

body.scanner-active .scanner-hide {
    visibility: hidden;
}
